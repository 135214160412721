import * as React from "react"
import "./Footer.scss"
import ronaLogo from "../../images/Rona logo white.svg"
import renoDepotLogo from "../../images/Reno Depot logo white.svg"
import dicksLumberLogo from "../../images/Dicks Lumber logo white.svg"

export default function Footer(props) {
    return (
        <>
            <footer className="page-footer">
                <div id="scrollTop" className="scrollTop">
                    <a id="scrollBtn" className="btn">
                        <span className="icon-ctn">
                            <i className="fas fa-chevron-up"/>
                            <i className="fas fa-chevron-up"/>
                        </span>
                    </a>
                </div>

                <div id="scrollBtnLimit" className="lowes-rooftop col-12 col-md-6"/>

                <div style={{backgroundColor: "#00315d"}} className=" d-flex mt5 justify-content-center">
                    <div className="col-10  footer-menu-section maxWidth">
                        <div className="row">
                            <div className="block">
                                <h2 className="block-title">
                                    <a href="https://www.google.ca/maps/place/220+Chemin+du+Tremblay,+Boucherville,+QC+J4B+8H7/@45.5657288,-73.4633389,13z/data=!4m5!3m4!1s0x4cc903052bc4823b:0x15fcd42db7b6e0e3!8m2!3d45.5639242!4d-73.421404"
                                       target="_blank" rel={"nofollow noreferrer"} className="google-map-link">
                                        {props.lang === "FR" ? "Siège social" : "Head Office"}
                                    </a>
                                </h2>
                                <div className="block-content">
                                    <p>{props.lang === "FR" ? "220, ch. du Tremblay" : "220, ch. du Tremblay"}</p>
                                    <p>{props.lang === "FR" ? "Boucherville (QC)" : "Boucherville (QC)"}</p>
                                    <p>J4B 8H7</p>
                                    <p><a href="tel:514 599-5900">514 599-5900</a></p>
                                    <p><a href="tel:1 877 599-5900">1 877 599-5900</a></p>
                                </div>
                            </div>
                            <div className="block">
                                <h2 className="block-title">
                                    <a target={"_blank"} rel={"nofollow noreferrer"}
                                       href={props.lang === "FR" ? "https://www.ronainc.ca/fr/a-propos": "https://www.ronainc.ca//en/about"}>{props.lang === "FR" ? "À propos de nous": "About Us"}
                                    </a>
                                </h2>
                                <div className="block-content">
                                    <ul className="link-menu">
                                        <li>
                                            <a target={"_blank"} rel={"nofollow noreferrer"}
                                               href={props.lang === "FR" ? "https://www.ronainc.ca/fr/bannieres" : "https://www.ronainc.ca//en/banners"}>
                                                {props.lang === "FR" ? "Nos enseignes" : "Our banners"} </a>
                                        </li>
                                        <li>
                                            <a target={"_blank"} rel={"nofollow noreferrer"}
                                               href={props.lang === "FR" ? "https://www.ronainc.ca/fr/a-propos#nos-marchands-affilies" : "https://www.ronainc.ca//en/about#our-affiliated-dealers"}>
                                                {props.lang === "FR" ? "Nos marchands affiliés" : "Our affiliated dealers"} </a>
                                        </li>
                                        <li>
                                            <a target={"_blank"} rel={"nofollow noreferrer"}
                                               href={props.lang === "FR" ? "https://www.ronainc.ca/fr/a-propos#nos-fournisseurs" : "https://www.ronainc.ca//en/about#our-suppliers"}>
                                                {props.lang === "FR" ? "Nos fournisseurs" : "Our suppliers"} </a>
                                        </li>
                                        <li>
                                            <a target={"_blank"} rel={"nofollow noreferrer"}
                                               href={props.lang === "FR" ? "https://www.ronainc.ca/fr/carrieres" : "https://www.ronainc.ca//en/careers"}>
                                                {props.lang === "FR" ? "Carrières chez RONA" : "Careers with RONA"} </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="block">
                                  <h2 className="block-title">
                                    <a target={"_blank"} rel={"nofollow noreferrer"}
                                       href={props.lang === "FR" ? "https://www.ronainc.ca/fr/responsabilite-corporative" : "https://www.ronainc.ca//en/corporate-responsibility"}>
                                        {props.lang === "FR" ? "RESPONSABILITÉ D’ENTREPRISE": "Corporate Responsibility"}
                                    </a>
                                </h2>
                                <div className="block-content">
                                    <ul className="link-menu">
                                        <li>
                                            <a target={"_blank"} rel={"nofollow noreferrer"}
                                               href={props.lang === "FR" ? "https://www.ronainc.ca/fr/responsabilite-corporative/communautes" : "https://www.ronainc.ca//en/corporate-responsibility/communities"}>
                                                {props.lang === "FR" ? "Nos communautés" : "Our communities"}</a>
                                        </li>
                                        <li>
                                            <a target={"_blank"} rel={"nofollow noreferrer"}
                                               href={props.lang === "FR" ? "https://www.ronainc.ca/fr/responsabilite-corporative/produits" : "https://www.ronainc.ca//en/corporate-responsibility/products"}>
                                                {props.lang === "FR" ? "Nos produits" : "Our products"}</a>
                                        </li>
                                        <li>
                                            <a target={"_blank"} rel={"nofollow noreferrer"}
                                               href={props.lang === "FR" ? "https://www.ronainc.ca/fr/responsabilite-corporative/operations" : "https://www.ronainc.ca//en/corporate-responsibility/operations"}>
                                                {props.lang === "FR" ? "Nos opérations" : "Our operations"}</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="block">
                                <h2 className="block-title">
                                    <a target={"_blank"} rel={"nofollow noreferrer"}
                                       href={props.lang === "FR" ? "https://www.ronainc.ca/fr/nouvelles" : "https://www.ronainc.ca//en/news"}>
                                        {props.lang === "FR" ? "Nouvelles" : "News"} </a>
                                </h2>
                                <div className="block-content">
                                    <ul className="link-menu">
                                        <li>
                                            <a target={"_blank"} rel={"nofollow noreferrer"}
                                               href={props.lang === "FR" ? "https://www.ronainc.ca/fr/nous-joindre#demandes-media" : "https://www.ronainc.ca//en/contact#media-inquiries"}>
                                                {props.lang === "FR" ? "Demandes média" : "Media inquiries"} </a>
                                        </li>
                                        <li>
                                            <a target={"_blank"} rel={"nofollow noreferrer"}
                                               href={props.lang === "FR" ? "https://www.ronainc.ca/fr/nouvelles#liste-de-distribution" : "https://www.ronainc.ca//en/news#mailing-list"}>
                                                {props.lang === "FR" ? "Abonnez-vous à notre liste de distribution" : "Sign up for alerts"} </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="social-accounts-footer">
                                <ul className="social-accounts d-flex">
                                    <li className="social-item">
                                        <a href="https://www.facebook.com/RONAcarrieres.careers" target="_blank">
                                            <i className="fab fa-facebook fa-2x"></i>
                                        </a>
                                    </li>
                                    <li className="social-item">
                                        <a href="https://twitter.com/RONAinc" target="_blank">
                                            <i className="fab fa-twitter fa-2x"></i>
                                        </a>
                                    </li>
                                    <li className="social-item">
                                        <a href="https://www.linkedin.com/company/rona/" target="_blank">
                                            <i className="fab fa-linkedin fa-2x"></i>
                                        </a>
                                    </li>
                                    <li className="social-item">
                                        <a href="https://www.youtube.com/@RONAinc/featured" target="_blank">
                                            <i className="fab fa-youtube fa-2x"></i>
                                        </a>
                                    </li>

                                </ul>

                            </div>
                        </div>
                    </div>
                </div>

                <div style={{backgroundColor: "#0073b9"}} className="light-blue-background">
                    <div className="footer-banners d-flex justify-content-center maxWidth">
                        <ul className="link-menu">
                            <li>
                                <a href="https://www.rona.ca/en" target="_blank">
                                    <img
                                        src={ronaLogo}
                                        alt="Rona"/>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.renodepot.com/en" target="_blank">
                                    <img
                                        src={renoDepotLogo}
                                        alt="Reno Depot"/>
                                </a>
                            </li>
                            <li>
                                <a href="http://www.dickslumber.com" target="_blank">
                                    <img
                                        src={dicksLumberLogo}
                                        alt="Dick's Lumber"/>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div style={{backgroundColor: "#0073b9", borderTop: 'solid 1px white'}} className="light-blue-background">
                    <div className="footer-notes d-flex justify-content-between maxWidth">
                        <div>
                            <p className="copyright">{props.lang === "FR" ? `© RONA INC. ${new Date().getFullYear()}, Tous droits réservés` : `© RONA INC. ${new Date().getFullYear()}, All rights reserved`}</p>
                        </div>
                        <div className="terms-privacy d-flex">
                            <ul className="link-menu">
                                <li>
                                    <a target={"_blank"} rel={"nofollow noreferrer"}
                                       href={props.lang === "FR" ? "https://www.ronainc.ca/fr/termes-et-conditions" : "https://www.ronainc.ca//en/terms-and-conditions"}>{props.lang === "FR" ? "Conditions d'utilisation": "Terms of use"} </a>
                                </li>
                                <li>
                                    <a target={"_blank"} rel={"nofollow noreferrer"}
                                       href={props.lang === "FR" ? "https://www.ronainc.ca/fr/déclaration-relative-à-la-vie-privée" : "https://www.ronainc.ca//en/privacy-statement"}>{props.lang === "FR" ? "Déclaration relative à la vie privée": "Privacy statement"} </a>
                                </li>
                                <li>
                                    <a target={"_blank"} rel={"nofollow noreferrer"}
                                       href={props.lang === "FR" ? "https://www.ronainc.ca/fr/accessibilite" : "https://www.ronainc.ca//en/accessibility"}>{props.lang === "FR" ? "Accessibilité": "Accessibility"} </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <script src={"https://ronainc.ca/scripts/scrollTop.js"}/>
                <div className={"py3 r jc ac"} style={{width: "100%", background: "#000000", color:"#ffffff"}}>
                    <p>{props.lang === "FR" ? "Propulsé par": "Powered By"} <a rel="nofollow noopener noreferrer" href={props.lang === "FR" ? "https://vidcruiter.com/applicant-tracking-system/custom-careers-pages/?utm_source=rona-inc-ccs": "https://vidcruiter.com/applicant-tracking-system/custom-careers-pages/?utm_source=rona-inc-ccs"} target={"_blank"}><u> VidCruiter</u></a></p>
                </div>
            </footer>
        </>
    )
}