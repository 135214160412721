import * as React from "react"
import Seo from "./../Seo"
import {Helmet} from "react-helmet";
import {Link} from "gatsby";
import ronaIncLogo from "../../images/rona_bleu.svg";
import "./Header.scss"

export default function Header(props) {
    let redirect = null;
        if(props.redirect){
            redirect = `0; URL=${props.redirect}`;
        }
    return (
      <>
        <Helmet bodyAttributes={{ class: props.bodyClasses }}>
          <link rel="icon" type="image/png" href={ronaIncLogo} sizes="32x32" />
          {/* Leaflet Stylesheet and Script*/}
          {props.redirect ? (
            <meta http-equiv="refresh" content={redirect} />
          ) : (
            ""
          )}
          <link
            rel="stylesheet"
            href={"https://www.ronainc.ca/styles/bootstrap-grid.min.css"}
          />
          <link
            rel="stylesheet"
            href={"https://unpkg.com/leaflet@1.7.1/dist/leaflet.css"}
            integrity="sha512-xodZBNTC5n17Xt2atTPuE1HxjVMSvLVW9ocqUKLsCC5CXdbqCmblAshOMAS6/keqq/sMZMZ19scR4PsZChSR7A=="
            crossOrigin=""
          />
          <script
            src={"https://unpkg.com/leaflet@1.7.1/dist/leaflet.js"}
            integrity="sha512-XQoYMqMTK8LvdxXYG3nZ448hOEQiglfqkJs1NOQV44cWnUrBc8PkAOcXy20w0vlaXaVUearIOBhiXZ5V3ynxwA=="
            crossOrigin=""
          />
          <link
            href={"https://fonts.googleapis.com/css?family=Lato:400,700,900"}
            rel="stylesheet"
          />
          <link
            rel="stylesheet"
            href={"https://use.fontawesome.com/releases/v5.3.1/css/all.css"}
            integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU"
            crossOrigin="anonymous"
          />
          <meta
            name="facebook-domain-verification"
            content={
              props.lang === "FR"
                ? "hrqkswhzslxaj16t2z65aul3pdupdz"
                : "7s5ekc8dguuvmvnf07f6roxqaz3dvs"
            }
          />
          <script defer src={"https://www.ronainc.ca/scripts/BurgerMenu.js"} />
          <script src={"https://ronainc.ca/scripts/swiper.min.js"} />
          <script src={"https://ronainc.ca/scripts/smoothscroll.js"} />
        </Helmet>

        <Seo description={props.description} title={props.title} />
        {!props.hidden ? (
          <header id="page-header" className="page-header">
            {/*<div id="important-notice" className={"important-notice"}>*/}
            {/*    <p className="maxWidth">*/}
            {/*        <i className="fas fa-exclamation-circle"/>&nbsp;&nbsp;&nbsp;*/}
            {/*        {props.lang === "FR" ? "COVID-19 : Comment Lowe's Canada répond à la pandémie." : "COVID-19: Lowe's Canada's response to the pandemic."}  &nbsp;&nbsp;*/}
            {/*        <a target={"_blank"} rel={"noreferrer nofollow"}*/}
            {/*           href={props.lang === "FR" ? "https://ronainc.ca/fr/covid-19-comment-lowes-canada-repond-a-la-pandemie" : "https://www.ronainc.ca/en/covid-19-lowes-canada-s-response-to-the-pandemic"}> {props.lang === "FR" ? "En savoir plus" : "Read more"} </a>*/}
            {/*    </p>*/}
            {/*</div>*/}

            <div id="page-navbar" className="page-navbar r jsb ac maxWidth">
              <div className="nav-logo c jc ac">
                <Link to="/" className={""}>
                  <img src={ronaIncLogo} width={250.89} alt="RONA inc. Logo" />
                </Link>
              </div>

              <div className="menu-container">
                <nav className="r jsb">
                  <ul className="mobile">
                    <li>
                      {/*<a className="link-text notice-red"*/}
                      {/*   target={"_blank"} rel={"nofollow noreferrer"}*/}
                      {/*   href="https://www.ronainc.ca/en/covid-19-lowes-canada-s-response-to-the-pandemic">*/}
                      {/*    <i className="fas fa-exclamation-circle"/> {props.lang === "FR" ? "Message important" : "Important message"}*/}
                      {/*</a>*/}
                    </li>
                  </ul>
                  <div className="mobile-flex">
                    <ul className="contact-lang-menu d-flex">
                      <li id="notice-link" className="notice-link">
                        {/*<a className="link-text desktop notice-red"*/}
                        {/*   target={"_blank"} rel={"nofollow noreferrer"}*/}
                        {/*   href="https://www.ronainc.ca/en/covid-19-lowes-canada-s-response-to-the-pandemic">*/}
                        {/*    <i className="fas fa-exclamation-circle"/> {props.lang === "FR" ? "Message important" : "Important message"}*/}
                        {/*</a>*/}
                      </li>

                      <li>
                        <a
                          className="link-text desktop"
                          target={"_blank"}
                          rel={"nofollow noreferrer"}
                          href={
                            props.lang === "FR"
                              ? "https://ronainc.ca/fr/nous-joindre"
                              : "https://www.ronainc.ca/en/contact"
                          }
                        >
                          {props.lang === "FR" ? "Nous joindre" : "Contact us"}
                        </a>
                      </li>

                      <li>
                        <Link
                          className="link-text desktop"
                          to={
                            props.lang_link
                              ? props.lang_link
                              : props.lang === "FR"
                              ? `${process.env.EN_URL}/`
                              : `${process.env.FR_URL}/`
                          }
                        >
                          {props.lang === "FR" ? "English" : "Français"}
                        </Link>
                      </li>
                    </ul>
                    <div id="burger-button" className="burger-button">
                      <i className="fas fa-bars" />
                    </div>
                  </div>

                  <div id="pages-menu" className="pages-menu">
                    <ul className="main-menu">
                      <li className="mobile-lang-ctn">
                        <div
                          id="pages-menu-closing-btn"
                          className="pages-menu-closing-btn"
                        >
                          <i className="far fa-window-close" />
                        </div>
                        <div>
                          <Link
                            className="link-text mobile"
                            to={
                              props.lang_link
                                ? props.lang_link
                                : props.lang === "FR"
                                ? `${process.env.EN_URL}/`
                                : `${process.env.FR_URL}/`
                            }
                          >
                            {props.lang === "FR" ? "English" : "Français"}
                          </Link>
                        </div>
                      </li>
                      <li className="desktop-hide">
                        <div className="link-ctn">
                          <a
                            className="link-text"
                            href={
                              props.lang === "FR"
                                ? "https://ronainc.ca/fr/"
                                : "https://ronainc.ca/en/"
                            }
                          >
                            {props.lang === "FR" ? "Accueil" : "Homepage"}
                          </a>
                        </div>
                      </li>
                      <li className="submenu-container">
                        <div className="link-ctn">
                          <a
                            className="link-text"
                            target={"_blank"}
                            rel={"nofollow noreferrer"}
                            href={
                              props.lang === "FR"
                                ? "https://www.ronainc.ca/fr/a-propos"
                                : "https://www.ronainc.ca/en/about"
                            }
                          >
                            {props.lang === "FR" ? "À propos" : "About us"}
                          </a>
                          <div className="submenu-toggle">
                            <i className="far fa-plus-square mobile-toggle" />
                            <i className="fas fa-chevron-down desktop-toggle" />
                          </div>
                        </div>
                        <ul className="submenu">
                          <li>
                            <a
                              target={"_blank"}
                              rel={"nofollow noreferrer"}
                              href={
                                props.lang === "FR"
                                  ? "https://www.ronainc.ca/fr/a-propos#Notre%20comit%C3%A9%20de%20direction"
                                  : "https://www.ronainc.ca//en/about#Our Senior Leadership Team"
                              }
                              className="submenu-link"
                            >
                              {props.lang === "FR"
                                ? "Notre comité de direction"
                                : "Our Senior Leadership Team"}
                            </a>
                          </li>
                          <li>
                            <a
                              target={"_blank"}
                              rel={"nofollow noreferrer"}
                              href={
                                props.lang === "FR"
                                  ? "https://www.ronainc.ca/fr/a-propos#nos-marchands-affilies"
                                  : "https://www.ronainc.ca/en/about#our-affiliated-dealers"
                              }
                              className="submenu-link"
                            >
                              {props.lang === "FR"
                                ? "Nos marchands affiliés"
                                : "Our affiliated dealers"}
                            </a>
                          </li>
                          <li>
                            <a
                              target={"_blank"}
                              rel={"nofollow noreferrer"}
                              href={
                                props.lang === "FR"
                                  ? "https://www.ronainc.ca/fr/a-propos#nos-fournisseurs"
                                  : "https://www.ronainc.ca/en/about#our-suppliers"
                              }
                              className="submenu-link"
                            >
                              {props.lang === "FR"
                                ? "Nos fournisseurs"
                                : "Our suppliers"}
                            </a>
                          </li>
                          <li>
                            <a
                              target={"_blank"}
                              rel={"nofollow noreferrer"}
                              href={
                                props.lang === "FR"
                                  ? "https://www.ronainc.ca/fr/a-propos#Install"
                                  : "https://www.ronainc.ca/en/about#Install"
                              }
                              className="submenu-link"
                            >
                              {props.lang === "FR"
                                ? "Nos fournisseurs"
                                : "Our installers"}
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className="submenu-container">
                        <div className="link-ctn">
                          <a
                            className="link-text"
                            target={"_blank"}
                            rel={"nofollow noreferrer"}
                            href={
                              props.lang === "FR"
                                ? "https://www.ronainc.ca/fr/bannieres"
                                : "https://www.ronainc.ca/en/banners"
                            }
                          >
                            {props.lang === "FR"
                              ? "Nos enseignes"
                              : "Our banners"}
                          </a>
                          <div className="submenu-toggle">
                            <i className="far fa-plus-square mobile-toggle" />
                            <i className="fas fa-chevron-down desktop-toggle" />
                          </div>
                        </div>
                        <ul className="submenu">
                          <li>
                            <a
                              target={"_blank"}
                              rel={"nofollow noreferrer"}
                              href={
                                props.lang === "FR"
                                  ? "https://www.ronainc.ca/fr/bannieres#lowes"
                                  : "https://www.ronainc.ca/en/banners#lowes"
                              }
                              className="submenu-link"
                            >
                              {props.lang === "FR" ? "Lowe's" : "Lowe's"}
                            </a>
                          </li>
                          <li>
                            <a
                              target={"_blank"}
                              rel={"nofollow noreferrer"}
                              href={
                                props.lang === "FR"
                                  ? "https://www.ronainc.ca/fr/bannieres#rona%20plus"
                                  : "https://www.ronainc.ca/en/banners#rona%20plus"
                              }
                              className="submenu-link"
                            >
                              {props.lang === "FR" ? "RONA+" : "RONA+"}
                            </a>
                          </li>
                          <li>
                            <a
                              target={"_blank"}
                              rel={"nofollow noreferrer"}
                              href={
                                props.lang === "FR"
                                  ? "https://www.ronainc.ca/fr/bannieres#rona"
                                  : "https://www.ronainc.ca/en/banners#rona"
                              }
                              className="submenu-link"
                            >
                              {props.lang === "FR" ? "RONA" : "RONA"}
                            </a>
                          </li>
                          <li>
                            <a
                              target={"_blank"}
                              rel={"nofollow noreferrer"}
                              href={
                                props.lang === "FR"
                                  ? "https://www.ronainc.ca/fr/bannieres#reno-depot"
                                  : "https://www.ronainc.ca/en/banners#reno-depot"
                              }
                              className="submenu-link"
                            >
                              {props.lang === "FR"
                                ? "Réno-Dépôt"
                                : "Reno-Depot"}
                            </a>
                          </li>
                          <li>
                            <a
                              target={"_blank"}
                              rel={"nofollow noreferrer"}
                              href={
                                props.lang === "FR"
                                  ? "https://www.ronainc.ca/fr/bannieres#dicks-lumber"
                                  : "https://www.ronainc.ca/en/banners#dicks-lumber"
                              }
                              className="submenu-link"
                            >
                              {props.lang === "FR"
                                ? "Dick's Lumber"
                                : "Dick's Lumber"}
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className="submenu-container">
                        <div className="link-ctn">
                          <a
                            className="link-text"
                            target={"_blank"}
                            rel={"nofollow noreferrer"}
                            href={
                              props.lang === "FR"
                                ? "https://www.ronainc.ca/fr/responsabilite-corporative"
                                : "https://www.ronainc.ca/en/corporate-responsibility"
                            }
                          >
                            {props.lang === "FR"
                              ? "Développement durable"
                              : "Sustainable Development"}
                          </a>
                          <div className="submenu-toggle">
                            <i className="far fa-plus-square mobile-toggle" />
                            <i className="fas fa-chevron-down desktop-toggle" />
                          </div>
                        </div>
                        <ul className="submenu">
                          <li>
                            <a
                              href={
                                props.lang === "FR"
                                  ? "https://www.ronainc.ca/fr/responsabilite-corporative#Notre%20rapport%20annuel"
                                  : "https://www.ronainc.ca/en/corporate-responsibility#Our%20annual%20report"
                              }
                              class="submenu-link"
                            >
                              {props.lang === "FR"
                                ? "Notre rapport annuel"
                                : "Our annual report"}
                            </a>
                          </li>
                          <li>
                            <a
                              target={"_blank"}
                              rel={"nofollow noreferrer"}
                              href={
                                props.lang === "FR"
                                  ? "https://www.ronainc.ca/fr/responsabilite-corporative#Nos%20produits"
                                  : "https://www.ronainc.ca/en/corporate-responsibility#Our%20products"
                              }
                              className="submenu-link"
                            >
                              {props.lang === "FR"
                                ? "Nos produits"
                                : "Our products"}
                            </a>
                          </li>
                          <li>
                            <a
                              target={"_blank"}
                              rel={"nofollow noreferrer"}
                              href={
                                props.lang === "FR"
                                  ? "https://www.ronainc.ca/fr/responsabilite-corporative#Nos%20op%C3%A9rations"
                                  : "https://www.ronainc.ca/en/corporate-responsibility#Our%20operations"
                              }
                              className="submenu-link"
                            >
                              {props.lang === "FR"
                                ? "Nos opérations"
                                : "Our operations"}
                            </a>
                          </li>
                          <li>
                            <a
                              target={"_blank"}
                              rel={"nofollow noreferrer"}
                              href={
                                props.lang === "FR"
                                  ? "https://www.ronainc.ca/fr/responsabilite-corporative#Nos%20communaut%C3%A9s"
                                  : "https://www.ronainc.ca/en/corporate-responsibility#Our%20communities"
                              }
                              className="submenu-link"
                            >
                              {props.lang === "FR"
                                ? "Nos communautés"
                                : "Our communities"}
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className="submenu-container">
                        <div className="link-ctn">
                          <a
                            className="link-text"
                            target={"_blank"}
                            rel={"nofollow noreferrer"}
                            href={
                              props.lang === "FR"
                                ? "https://www.ronainc.ca/fr/carrieres"
                                : "https://www.ronainc.ca/en/careers"
                            }
                          >
                            {props.lang === "FR" ? "Carrières" : "Careers"}
                          </a>
                          <div className="submenu-toggle">
                            <i className="far fa-plus-square mobile-toggle" />
                            <i className="fas fa-chevron-down desktop-toggle" />
                          </div>
                        </div>
                        <ul className="submenu">
                          <li>
                            <a
                              href={
                                props.lang === "FR"
                                  ? "https://www.ronainc.ca/fr/carrieres#emplois"
                                  : "https://www.ronainc.ca/en/careers#jobs"
                              }
                              className="submenu-link same-page-link"
                            >
                              {props.lang === "FR" ? "Emplois" : "Jobs"}
                            </a>
                          </li>
                          <li>
                            <a
                              href={
                                props.lang === "FR"
                                  ? "https://www.ronainc.ca/fr/carrieres#diversite-inclusion"
                                  : "https://www.ronainc.ca/en/careers#diversity-inclusion"
                              }
                              className="submenu-link same-page-link"
                            >
                              {props.lang === "FR"
                                ? "Diversité et inclusion"
                                : "Diversity and inclusion"}
                            </a>
                          </li>
                          <li>
                            <a
                              href={
                                props.lang === "FR"
                                  ? "https://www.ronainc.ca/fr/carrieres#temoignage"
                                  : "https://www.ronainc.ca/en/careers#testimony"
                              }
                              className="submenu-link same-page-link"
                            >
                              {props.lang === "FR"
                                ? "Témoignages"
                                : "Testimonies"}
                            </a>
                          </li>
                          <li>
                            <a
                              href={
                                props.lang === "FR"
                                  ? "https://www.ronainc.ca/fr/carrieres#Lowes-canada-tech"
                                  : "https://www.ronainc.ca/en/careers#Lowes-canada-tech"
                              }
                              className="submenu-link"
                            >
                              {props.lang === "FR" ? "RONA Tech" : "RONA Tech"}
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className="">
                        <div className="link-ctn">
                          <a
                            className="link-text"
                            target={"_blank"}
                            rel={"nofollow noreferrer"}
                            href={
                              props.lang === "FR"
                                ? "https://www.ronainc.ca/fr/nouvelles"
                                : "https://www.ronainc.ca/en/news"
                            }
                          >
                            {props.lang === "FR" ? "Nouvelles" : "News"}
                          </a>
                        </div>
                      </li>

                      <li className="mobile-hidden-item">
                        <div className="link-ctn d-flex justify-content-between align-items-center">
                          <a
                            className="link-text mobile"
                            target={"_blank"}
                            rel={"nofollow noreferrer"}
                            href={
                              props.lang === "FR"
                                ? "https://www.ronainc.ca/fr/nous-joindre"
                                : "https://www.ronainc.ca/en/contact"
                            }
                          >
                            {props.lang === "FR"
                              ? "Nous-Joindres"
                              : "Contact us"}
                          </a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
            <div className="breadcrumb maxWidth">
              <ul>
                {props.breadcrumbs
                  ? props.breadcrumbs.reverse().map((breadcrumb, i, arr) => (
                      <li>
                        <Link to={breadcrumb.url} className="link-text">
                          {breadcrumb.text}
                        </Link>
                        {arr.length > i + (arr.length - 1) ? (
                          ""
                        ) : (
                          <i className="fas fa-chevron-right" />
                        )}
                      </li>
                    ))
                  : ""}
                <li>
                  <Link to="/" className="link-text">
                    {props.lang === "FR" ? "Carrières" : "Careers"}
                  </Link>
                  {props.breadcrumbs ? (
                    <i className="fas fa-chevron-right" />
                  ) : (
                    ""
                  )}
                </li>
                {/*<li>*/}
                {/*    <a className="link-text"*/}
                {/*       href={props.lang === "FR" ? "https://ronainc.ca/fr/" : "https://ronainc.ca/en/"}>{props.lang === "FR" ? "Accueil" : "Homepage"}</a>*/}
                {/*    <i className="fas fa-chevron-right"/>*/}
                {/*</li>*/}
              </ul>
            </div>
          </header>
        ) : (
          ""
        )}
      </>
    );
}
